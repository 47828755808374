<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('cotton_report.ginner_information_report') }}</h4>
        </template>
        <template v-slot:body>
          <b-row>
              <!-- <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('warehouse_config.fiscal_year')"
                      label-for="fiscal_year_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col> -->
              <b-col xs="12" sm="12" md="6" lg="3" xl="3" vid="org_id" rules="required|min_value:0">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('org_pro.organization')"
                      label-for="org_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="organizationList"
                          id="org_id"
                          disabled
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <!-- <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('cotton_ginner_grower.season')"
                      label-for="seasons_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.seasons_id"
                          :options="seasonList"
                          id="seasons_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('cotton_config.region')"
                      label-for="region_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.region_id"
                          :options="regionList"
                          id="region_id"
                          :disabled="isOfficeUser"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('cotton_config.zone')"
                      label-for="zone_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.zone_id"
                          :options="zoneList"
                          id="zone_id"
                          :disabled="isOfficeUser"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('cotton_config.unit')"
                      label-for="unit_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.unit_id"
                          :options="unitList"
                          id="unit_id"
                          :disabled="isOfficeUser"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col> -->
              <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.district')"
                      label-for="district_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="getDistrictList"
                          id="district_id"
                          :disabled="isOfficeUser"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.upazila')"
                      label-for="upazila_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.upazila_id"
                          :options="upazilaList"
                          id="upazila_id"
                          :disabled="isOfficeUser"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
              </b-col>
          </b-row>
        </template>
    </iq-card>
        <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{  $t('cotton_report.ginner_information_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <export-excel
                            class="btn btn_add_new"
                            :data="dataCustomizeExcel"
                            :fields="json_fields"
                            :title="$t('cotton_report.ginner_information_report')"
                            worksheet="Ginner Information Report List"
                            name="filename.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="agriMarketingServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                                  {{ $t('cotton_report.ginner_information_report') }}
                                </list-report-head>
                              </b-col>
                              </b-row>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <!-- <b-table bordered hover :items="deliveryScheduleList" :fields="columns" :tbody-tr-class="rowClass" aria-hidden="loading | listReload ? 'true' : null"> -->
                                 <b-table bordered hover :items="growerInfoReport" :fields="columns" :emptyText="$t('globalTrans.noDataFound')" thead-class="bg-primary" show-empty>
                                  <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                  <template v-slot:cell(land_area)="data">
                                    {{ $n(data.item.land_area, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(mobile_no)="data">
                                    {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile_no, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(nid)="data">
                                    {{ $n(data.item.nid, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(status)="data">
                                    {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                                  </template>
                                </b-table>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, ginnerInformationReportList } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  // Zone: 'zone',
  // Unit: 'unit',
  District: 'district',
  Upazilla: 'upazilla',
  'Ginner Name': 'name_x',
  'Father Name': 'fatherName',
  Address: 'address_x',
  'Mobile Number': 'mobileNumber',
  Nid: 'nidCustom'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  // অঞ্চল: 'zone',
  // ইউনিট: 'unit',
  জেলা: 'district',
  উপজেলা: 'upazilla',
  'জিনারের নাম': 'name_x',
  বাবারনাম: 'fatherName',
  ঠিকানা: 'address_x',
  মোবাইলনম্বর: 'mobileNumber',
  এনআইডি: 'nidCustom'
}

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 10,
        fiscal_year_id: 0,
        region_id: 0,
        zone_id: 0,
        unit_id: 0,
        district_id: 0,
        upazila_id: 0,
        seasons_id: 0
      },
      zoneList: [],
      unitList: [],
      upazilaList: [],
      organization: [],
      excelData: [],
      growerInfoReport: [],
      testId: 0,
      rows: [],
      agriMarketingServiceBaseUrl: agriMarketingServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      loading: false
    }
  },
  computed: {
      json_fields: function () {
        return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
      },
      currentLocale () {
        return this.$i18n.locale
      },
      dataCustomizeExcel () {
        var serial = 0
        const customizeData = this.growerInfoReport.map(item => {
          serial += 1
          if (this.currentLocale === 'bn') {
            item.serial = this.$n(serial)
            // item.zone = item.zone_name_bn
            // item.unit = item.unit_name_bn
            item.district = item.district_name_bn
            item.upazilla = item.upazilla_name_bn
            item.name_x = item.name_bn
            item.fatherName = item.father_name_bn
            item.address_x = item.address_bn
            item.mobileNumber = this.$n(item.mobile_no, { useGrouping: false })
            // item.landArea = this.$n(item.land_area)
            item.nidCustom = this.$n(item.nid, { useGrouping: false })
          } else {
            item.serial = this.$n(serial)
            // item.zone = item.zone_name
            // item.unit = item.unit_name
            item.district = item.district_name
            item.upazilla = item.upazilla_name
            item.name_x = item.name
            item.fatherName = item.father_name
            item.address_x = item.address
            item.mobileNumber = this.$n(item.mobile_no)
            // item.landArea = this.$n(item.land_area)
            item.nidCustom = this.$n(item.nid, { useGrouping: false })
          }
          return Object.assign({}, item)
        })
        return customizeData
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          // { label: this.$t('cotton_ginner_grower.zone'), class: 'text-left' },
          // { label: this.$t('cotton_ginner_grower.unit_name'), class: 'text-left' },
          { label: this.$t('org_pro_district.district'), class: 'text-left' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-left' },
          { label: this.$t('globalTrans.company_name'), class: 'text-left' },
          { label: this.$t('cotton_ginner_grower.ginnerName'), class: 'text-left' },
          { label: this.$t('cotton_report.address'), class: 'text-left' },
          { label: this.$t('cotton_report.mobile_number'), class: 'text-left' },
        //   { label: this.$t('cotton_ginner_grower.land_area_acre'), class: 'text-left' },
          { label: this.$t('cotton_report.nid'), class: 'text-center' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          // { key: 'zone_name_bn' },
          // { key: 'unit_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'name_bn' },
          { key: 'father_name_bn' },
          { key: 'address_bn' },
          { key: 'mobile_no' },
        //   { key: 'land_area' },
          { key: 'nid' }
        ]
      } else {
        keys = [
          { key: 'index' },
          // { key: 'zone_name' },
          // { key: 'unit_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'name' },
          { key: 'father_name' },
          { key: 'address' },
          { key: 'mobile_no' },
        //   { key: 'land_area' },
          { key: 'nid' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    fiscalList: function () {
      const fiscalyearData = this.$store.state.commonObj.fiscalYearList
      return fiscalyearData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    regionList: function () {
       return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
    },
    seasonList: function () {
      return this.$store.state.agriMarketing.commonObj.seasonList.filter(item => item.status === 1)
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    getDistrictList () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    isOfficeUser () {
      // return !(this.$store.state.Auth.activeRoleId === 1 || this.$store.state.Auth.authUser.is_org_admin)
      return false
    }
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
      this.zoneList = this.getZoneList(newVal)
    },
    'search.zone_id': function (newVal, oldVal) {
      this.unitList = this.getUnitList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal) {
        this.zoneList = this.getZoneList(this.search.region_id)
        this.upazilaList = this.getUpazilaList(this.search.district_id)
        this.unitList = this.getUnitList(this.search.zone_id)
      }
    }
  },
  created () {
    this.loadData()
    if (this.isOfficeUser) {
      const authOfficeDetail = this.$store.state.Auth.authUser.office_detail
      const unit = this.$store.state.agriMarketing.commonObj.unitList.find(obj => obj.district_id === authOfficeDetail.district_id)
      if (unit !== undefined) {
        this.search.region_id = unit.region_id
        this.search.zone_id = unit.zone_id
        this.search.unit_id = unit.value
        this.search.district_id = unit.district_id
        this.search.upazila_id = unit.upazilla_id
      }
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getZoneList (regionId = null) {
      const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.status === 1)
      if (regionId) {
        return zoneList.filter(region => region.region_id === regionId)
      }
      return zoneList
    },
    getUnitList (ZoneId = null) {
      const unitList = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1)
      if (ZoneId) {
        return unitList.filter(unit => unit.zone_id === ZoneId)
      }
      return unitList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.organizationData()
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === this.search.org_id)

      RestApi.getData(agriMarketingServiceBaseUrl, reportHeadingList + '/' + this.search.org_id, { org_id: this.search.org_id }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Organization not found!'
          })
        }
      })
    },
    loadData () {
      this.loading = true
      RestApi.getData(agriMarketingServiceBaseUrl, ginnerInformationReportList, this.search)
      .then(response => {
        if (response.success) {
          this.growerInfoReport = this.dataList(response.data)
        }
        this.loading = false
      }).catch(error => {
        if (error) {
          //
        }
        this.growerInfoReport = []
      })
    },
    dataList (data) {
      const regionNameList = this.$store.state.agriMarketing.commonObj.regionList
      const zoneNameList = this.$store.state.agriMarketing.commonObj.zoneList
      const unitNameList = this.$store.state.agriMarketing.commonObj.unitList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      return data.map(item => {
          const regionNameObject = regionNameList.find(region => region.value === item.region_id && region.status === 1)
          const zoneNameObject = zoneNameList.find(zone => zone.value === item.zone_id && zone.status === 1)
          const unitNameObject = unitNameList.find(unit => unit.value === item.unit_id && unit.status === 1)
          const districtNameObject = districtList.find(district => district.value === item.district_id && district.status === 0)
          const upazillaNameObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id && upazilla.status === 0)
          const regionData = {
            region_name: regionNameObject !== undefined ? regionNameObject.text_en : '',
            region_name_bn: regionNameObject !== undefined ? regionNameObject.text_bn : ''
          }
          const zoneData = {
            zone_name: zoneNameObject !== undefined ? zoneNameObject.text_en : '',
            zone_name_bn: zoneNameObject !== undefined ? zoneNameObject.text_bn : ''
          }
          const unitData = {
            unit_name: unitNameObject !== undefined ? unitNameObject.text_en : '',
            unit_name_bn: unitNameObject !== undefined ? unitNameObject.text_bn : ''
          }
          const districtData = {
            district_name: districtNameObject !== undefined ? districtNameObject.text_en : '',
            district_name_bn: districtNameObject !== undefined ? districtNameObject.text_bn : ''
          }
          const upazillaData = {
            upazilla_name: upazillaNameObject !== undefined ? upazillaNameObject.text_en : '',
            upazilla_name_bn: upazillaNameObject !== undefined ? upazillaNameObject.text_bn : ''
          }
          return Object.assign({}, item, regionData, zoneData, unitData, districtData, upazillaData)
      })
    },
    pdfExport () {
      const rowData = this.getPdfData()

      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          // { text: this.$t('cotton_ginner_grower.zone'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          // { text: this.$t('cotton_ginner_grower.unit_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('org_pro_district.district'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('org_pro_upazilla.upazilla'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('globalTrans.company_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('cotton_ginner_grower.ginnerName'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('cotton_report.address'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('cotton_report.mobile_number'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        //   { text: this.$t('cotton_report.land_area'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('cotton_report.nid'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Ginner Information Report' : 'জিনার তথ্য প্রতিবেদন'
      const columnWids = ['5%', '10%', '10%', '15%', '15%', '15%', '15%', '15%']
      ExportPdf.exportPdf(agriMarketingServiceBaseUrl, reportHeadingList, this.search.org_id, reportTitle, rowData, columnWids, {}, 'MOA', false)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          // { key: this.$i18n.locale === 'en' ? 'zone_name' : 'zone_name_bn' },
          // { key: this.$i18n.locale === 'en' ? 'unit_name' : 'unit_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'district_name' : 'district_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'upazilla_name' : 'upazilla_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'name' : 'name_bn' },
          { key: this.$i18n.locale === 'en' ? 'father_name' : 'father_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'address' : 'address_bn' },
          { key: this.$i18n.locale === 'en' ? 'mobile_no' : 'mobile_no' },
        //   { key: this.$i18n.locale === 'en' ? 'land_area' : 'land_area' },
          { key: this.$i18n.locale === 'en' ? 'nid' : 'nid' }
        ]
      var serial = 0
      const listData = this.growerInfoReport.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'land_area') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'mobile_no') {
                return { text: this.$n(item[keyItem.key], { useGrouping: false }) }
            }
            if (keyItem.key === 'nid') {
                return { text: this.$n(item[keyItem.key], { useGrouping: false }) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
